import { Alert, Box, Button, IconButton, TextField, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import type { SCSSClasses } from "@typeDefs/scss.types";
import { mergeClassNames } from "@utils/scss.utils";
import clsx from "clsx";
import React, { ChangeEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "../../components/Link";
import GoogleButton from "../../components/oidc/GoogleButton";
import {
  LOCAL_STORAGE_AUTH_ATTEMPTS,
  LOCAL_STORAGE_AUTH_REAUTH,
  LOCAL_STORAGE_AUTH_REDIRECT,
} from "../../consts/storageKeys";
import { useUserContext } from "../../context/UserContext";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { useCaptureError } from "../../hooks/useCaptureError";
import { useGoogleLogin } from "../../hooks/useGoogleLogin";
import { useOurRouter } from "../../hooks/useOurRouter";
import LogoSvg from "../../img/logomark512maskable.svg";
import { reclaim } from "../../reclaim-api/index";
import { QueryState } from "../../types/query";
import { getSessionStorage, removeLocalStorage, removeSessionStorage } from "../../utils/local-storage";
import { browser } from "../../utils/platform";
import { getUserOnboardingPath } from "../../utils/router";
import LandingLayout from "../LandingLayout";
import moduleClasses from "./LoginLayout.module.scss";

export type LoginLayoutCSSClassKey = keyof typeof moduleClasses;

export type LoginLayoutProps = {
  title: string;
  googleBtnText?: string;
  className?: string;
  displayLoginButton: boolean;
  officeNotice?: boolean;
  displaySSO?: boolean;
  displayPreview?: boolean;
  hideBanner?: boolean;
  classes?: SCSSClasses<LoginLayoutCSSClassKey>;
};

export const LoginLayout: React.FC<LoginLayoutProps> = ({
  title,
  googleBtnText = "Continue with Google",
  displayLoginButton,
  officeNotice,
  displaySSO = true,
  displayPreview = false,
  hideBanner = false,
  className,
  classes: extClasses,
  children,
}) => {
  const theme = useTheme();
  const classes = mergeClassNames(moduleClasses, extClasses || {});

  /********************/
  /*   custom hooks   */
  /********************/
  const medium = useMediaQuery(theme.breakpoints.down("lg"));
  const router = useOurRouter<{ reason: string; state: QueryState }>();
  const [{ user, isAuthenticated, status }] = useUserContext();
  const handleGoogleLogin = useGoogleLogin();
  const { captureError } = useCaptureError();

  /********************/
  /*     useState     */
  /********************/
  const [error, setError] = useState<string | undefined>();
  const [ssoEmail, setSsoEmail] = useState<string | undefined>();
  const [isSso, setIsSso] = useState<boolean>(false);
  const ssoInputRef = useRef<HTMLInputElement>(null);

  /********************/
  /* useMemo & consts */
  /********************/
  const urlParams = useMemo(() => new URLSearchParams(browser().isBrowser ? window.location.search : ""), []);

  /********************/
  /*    useCallback   */
  /********************/
  const handleClick = useCallbackSafeRef((provider: string) => {
    if (provider === "google") {
      handleGoogleLogin();
    }
  });

  const handleSSOInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSsoEmail(e.target.value);
  };

  const handleSSOLogin = async () => {
    if (!ssoEmail) {
      setError("No SSO Email provided.");
      return;
    }

    try {
      const urlPath = await reclaim.users.ssoLogin(ssoEmail);

      if (urlPath) {
        reclaim.users.authRedirect(urlPath.replace("/oauth/login/", ""));
        return;
      }
      setError("No associated SSO email found.");
    } catch (error) {
      setError("Error while feching SSO URL.");
      captureError(new Error("Error while fetching URL", { cause: error }));
    }
  };

  const handleSSOKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      void handleSSOLogin();
    }
  };

  const handleRemoveError = () => {
    void router.replace({ query: {...router.query, reason: null }});
    setError(undefined);
  };

  /********************/
  /*    useEffects    */
  /********************/
  useEffect(() => {
    if (!router.query?.reason) return;
    const reason = router.query.reason;

    setError(
      "USER_DISABLED" === reason
        ? "Calendar for this email address is linked to another account. Please sign in using your other Google login."
        : "User not authorized."
    );
  }, [router.query?.reason]);

  // focus when isSso changes to true
  useEffect(() => {
    isSso && ssoInputRef.current?.focus();
  }, [isSso]);

  // Redirect if user is already authenticated
  useEffect(() => {
    if (!router.isReady || !isAuthenticated) return;

    removeLocalStorage(LOCAL_STORAGE_AUTH_REAUTH);
    removeLocalStorage(LOCAL_STORAGE_AUTH_ATTEMPTS);

    const slackTeamId = urlParams.get("slackTeamId");
    const slackUserId = urlParams.get("slackUserId");

    // the check for "://" prevents arbitrary redirection (Open Redirect)
    const oauthRedirect = router.query.state?.redirect;
    const redirect =
      !!oauthRedirect && !oauthRedirect.includes("://")
        ? oauthRedirect
        : getSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT);

    if (redirect) {
      removeSessionStorage(LOCAL_STORAGE_AUTH_REDIRECT);
    }

    // connect slack user
    if (slackUserId && slackTeamId) {
      reclaim.slack.link(slackUserId, slackTeamId).catch((reason) => {
        if (404 === reason.status) {
          captureError(new Error("Slack user not found, redirecting" + reason));
          reclaim.slack.authRedirect(slackTeamId);
          return;
        } else {
          captureError(new Error("Failed to link slack user " + reason));
        }
      });
    }

    let pathname = getUserOnboardingPath("welcome");

    if (user?.onboarded) {
      if (redirect) pathname = redirect;
      else if (medium) pathname = "/stats";
      else pathname = "planner";
    }

    void router.push({
      pathname,
      query: router.query,
    });
  }, [isAuthenticated, medium, router, captureError, urlParams, user?.onboarded]);

  return (
    <LandingLayout
      className={clsx(classes.root, className)}
      title={title}
      loading={["init", "loading"].includes(status) || !!isAuthenticated}
      hideNav
      hideBanner={hideBanner}
    >
      <Grid
        container
        className={clsx(classes.gridContainer, { [classes.previewBackground]: displayPreview })}
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.wrapper}>
          <IconButton
            className={classes.logoBtn}
            component={Link}
            href={!!user ? "/" : "//reclaim.ai"}
            size="medium"
            aria-label="reclaim.ai"
            disableTouchRipple
            disableRipple
            disableFocusRipple
          >
            <LogoSvg className={classes.logoBtn__svg} />
          </IconButton>
          {children}
          {!!error && (
            <Alert severity="error" variant="filled" className={classes.alert}>
              {error}&nbsp; If you believe this is an error, please contact support.
            </Alert>
          )}
          {isSso && !!displaySSO && (
            <Box className={classes.sso}>
              <TextField
                InputProps={{
                  className: classes.sso__input,
                }}
                ref={ssoInputRef}
                type="text"
                value={ssoEmail}
                variant="outlined"
                placeholder="Enter your email"
                onChange={handleSSOInputChange}
                onKeyPress={handleSSOKeyPress}
              />
              <Button variant="contained" color="primary" className={classes.sso__btn} onClick={handleSSOLogin}>
                Log in with SSO
              </Button>
            </Box>
          )}
          {!!displayLoginButton && !isSso && (
            <GoogleButton
              dark={displayPreview}
              label={googleBtnText}
              onClick={() => {
                handleRemoveError();
                handleClick("google");
              }}
              className={classes.googleBtn}
            />
          )}
          {!!displayLoginButton && !isSso && (
            <>
              {router.pathname.startsWith("/login") ? (
                <Typography className={classes.subtext} variant="body2">
                  Don't have an account? <Link href="/signup">Sign up</Link>
                </Typography>
              ) : (
                <Typography className={classes.subtext} variant="body2">
                  Have an account? <Link href="/login">Log in</Link>
                </Typography>
              )}
            </>
          )}
          {!!displaySSO &&
            (!isSso ? (
              <Typography className={classes.subtext} variant="body2">
                <Link
                  onClick={() => {
                    handleRemoveError();
                    setIsSso(true);
                  }}
                >
                  Log in with SSO
                </Link>
              </Typography>
            ) : (
              <Typography className={classes.subtext} variant="body2">
                <Link
                  onClick={() => {
                    handleRemoveError();
                    setIsSso(false);
                  }}
                >
                  Go back
                </Link>
              </Typography>
            ))}
          {!!officeNotice && (
            <Typography variant="body2" className={classes.subtext}>
              <Link href="mailto:hello@reclaim.ai?subject=Re:%20Office%20365%20Support%20for%20Reclaim&body=Hi%20Reclaim%20Team!%0D%0A%0D%0AI'm%20an%20Office%20365%20user%20and%20would%20love%20to%20stay%20updated%20when%20Reclaim%20works%20for%20O365%20calendars.%0D%0A%0D%0AThanks!">
                Join O365 waitlist
              </Link>
            </Typography>
          )}
        </Box>
      </Grid>
    </LandingLayout>
  );
};
